/* eslint-disable no-useless-escape */
import axios from "axios"

import { LOCAL_STORAGE, METHOD_TYPES } from "./constants"
import { BASE_URL, PLATFORM } from "./urls"
import { NOTIFICATION_AUDIO } from "../assets"
import { clsx } from "clsx"
import { twMerge } from "tailwind-merge"

const notificationAudio = new Audio(NOTIFICATION_AUDIO)

export const playNotification = () => {
  notificationAudio.play().catch((err) => {
    log(err)
  })
}

export const log = (...arg) => {
  if (process.env.NODE_ENV !== "production") console.log(...arg)
}

export const getUrlParams = () => {
  let params = {}
  const searchParams = new URLSearchParams(window.location.search)
  for (const [key, value] of searchParams.entries()) {
    params[key] = value
  }
  if (params.parentUrl) params.parentUrl = decodeURIComponent(params.parentUrl)
  return params
}

export const uniqueId = (key) => {
  const uid = Math.random().toString(16).substring(2)
  const params = getUrlParams()
  return `${key || ""}${uid}_${params?.tenantId ? params.tenantId : ""}`
}

export const sendUpdateToParent = (type, payload = {}, target = "*") => {
  if (window.parent)
    window.parent.postMessage(
      {
        type,
        payload,
      },
      target
    )
}

export const clearDataFromLocalStorage = (keys) => {
  if (!keys) {
    localStorage.removeItem(LOCAL_STORAGE.THEME_VERSION)
  } else if (keys && keys.length > 0) {
    keys.forEach((key) => localStorage.removeItem(key))
  }
}

export const getDataFromLocalStorage = (key, defaultValue) => {
  const tenantId = getUrlParams().tenantId
  const data = localStorage.getItem(`${key}-${tenantId}`)
  return data && data !== undefined ? JSON.parse(data) : defaultValue
}

export const setDataInLocalStorage = (key, data) => {
  const tenantId = getUrlParams().tenantId
  const jsonData = JSON.stringify(data)
  localStorage.setItem(`${key}-${tenantId}`, jsonData)
}

export const removeDataFromLocalStorage = (key) => {
  const tenantId = getUrlParams().tenantId
  localStorage.removeItem(`${key}-${tenantId}`)
}

export const getPsid = () => {
  let psid = getDataFromLocalStorage(LOCAL_STORAGE.PSID, null)
  if (!psid) {
    psid = uniqueId()
    setDataInLocalStorage(LOCAL_STORAGE.PSID, psid)
  }
  return psid
}

export const fetchDataAndProceed = async ({
  url,
  method = METHOD_TYPES.GET,
  data = {},
  timeout = 20000,
  contentType = "application/json",
  responseType,
  baseURL = BASE_URL,
  axiosArgs = {},
}) => {
  try {
    const res = await axios({
      url,
      baseURL,
      method,
      timeout,
      responseType,
      headers: { "Content-Type": contentType, 'x-served-for': window.location.hostname },
      params: method === METHOD_TYPES.GET ? data : {},
      data: method !== METHOD_TYPES.GET ? data : {},
      validateStatus: (status) =>
        (status >= 200 && status < 300) || status === 412,
      ...axiosArgs,
    })
    return res
  } catch (error) {
    return error.response
  }
}

// export const timeTagFormat = (timestamp) => {
//   const currentDate = new Date()
//   const date = new Date(timestamp)
//   currentDate.setHours(0, 0, 0, 0)
//   if (date - currentDate >= 0) return "Today"
//   if (currentDate - date <= 24 * 60 * 60 * 1000) return "Yesterday"
//   if (currentDate - date <= 6 * 24 * 60 * 60 * 1000) {
//     const day = date.getDay()
//     switch (day) {
//       case 0:
//         return "Sunday"
//       case 1:
//         return "Monday"
//       case 2:
//         return "Tuesday"
//       case 3:
//         return "Wednesday"
//       case 4:
//         return "Thursday"
//       case 5:
//         return "Friday"
//       case 6:
//         return "Saturday"
//       default:
//         return
//     }
//   }
//   return date.toLocaleDateString("en-In", {
//     year: "numeric",
//     month: "long",
//     day: "numeric",
//   })
// }

export const linkify = (inputText) => {
  let linkifiedText = inputText

  // URLs starting with http://, https://, or ftp://
  let urlPattern =
    /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim
  linkifiedText = inputText.replace(
    urlPattern,
    '<a href="$1" target="_blank">$1</a>'
  )

  // URLs starting with "www." (without // before it, or it'd re-link the ones done above).
  let pseudoUrlPattern = /(^|[^\/])(www\.[\S]+(\b|$))/gim
  linkifiedText = linkifiedText.replace(
    pseudoUrlPattern,
    '$1<a href="http://$2" target="_blank">$2</a>'
  )

  // Change email addresses to mailto:: links.
  let emailPattern = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim
  linkifiedText = linkifiedText.replace(
    emailPattern,
    '<a href="mailto:$1">$1</a>'
  )
  return linkifiedText
}

export const isAppleDevice = () => {
  return /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform)
}

export const throttled = (func, delay) => {
  let timerId
  return (...args) => {
    if (!timerId) {
      func(...args)
      timerId = setTimeout(() => {
        timerId = null
      }, delay)
    }
  }
}

export const isEmail = (email) => {
  const regex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return regex.test(String(email).toLowerCase())
}

export const createPayloadForApi = ({
  type,
  data = {},
  metadata = {},
  psid,
}) => {
  const payload = {
    type,
    data,
    metadata,
    id: uniqueId("API-"),
    info: {
      psid,
      tenantId: getUrlParams().tenantId,
      platformName: PLATFORM,
      sessionId: sessionStorage.getItem(LOCAL_STORAGE.SESSION_ID), 
    },
  }
  return payload
}

export const getBrowserDetails = () => {
  if ("userAgent" in navigator) {
    var nAgt = navigator.userAgent
    var browser = ""
    var version = "" + parseFloat(navigator.appVersion)
    var nameOffset, verOffset
    // Opera
    if ((verOffset = nAgt.indexOf("Opera")) !== -1) {
      browser = "Opera"
      version = nAgt.substring(verOffset + 6)
      if ((verOffset = nAgt.indexOf("Version")) !== -1) {
        version = nAgt.substring(verOffset + 8)
      }
    }
    // Opera Next
    if ((verOffset = nAgt.indexOf("OPR")) !== -1) {
      browser = "Opera"
      version = nAgt.substring(verOffset + 4)
    }
    // Legacy Edge
    else if ((verOffset = nAgt.indexOf("Edge")) !== -1) {
      browser = "Microsoft Legacy Edge"
      version = nAgt.substring(verOffset + 5)
    }
    // Edge (Chromium)
    else if ((verOffset = nAgt.indexOf("Edg")) !== -1) {
      browser = "Microsoft Edge"
      version = nAgt.substring(verOffset + 4)
    }
    // MSIE
    else if ((verOffset = nAgt.indexOf("MSIE")) !== -1) {
      browser = "Microsoft Internet Explorer"
      version = nAgt.substring(verOffset + 5)
    }
    // Chrome
    else if ((verOffset = nAgt.indexOf("Chrome")) !== -1) {
      browser = "Chrome"
      version = nAgt.substring(verOffset + 7)
    }
    // Safari
    else if ((verOffset = nAgt.indexOf("Safari")) !== -1) {
      browser = "Safari"
      version = nAgt.substring(verOffset + 7)
      if ((verOffset = nAgt.indexOf("Version")) !== -1) {
        version = nAgt.substring(verOffset + 8)
      }
    }
    // Firefox
    else if ((verOffset = nAgt.indexOf("Firefox")) !== -1) {
      browser = "Firefox"
      version = nAgt.substring(verOffset + 8)
    }
    // MSIE 11+
    else if (nAgt.indexOf("Trident/") !== -1) {
      browser = "Microsoft Internet Explorer"
      version = nAgt.substring(nAgt.indexOf("rv:") + 3)
    }
    // Other browsers
    else if (
      (nameOffset = nAgt.lastIndexOf(" ") + 1) <
      (verOffset = nAgt.lastIndexOf("/"))
    ) {
      browser = nAgt.substring(nameOffset, verOffset)
      version = nAgt.substring(verOffset + 1)
      if (browser.toLowerCase() === browser.toUpperCase()) {
        browser = navigator.appName
      }
    }
    return `${browser} ${version}`
  } else return ""
}

export const fileToBase64 = (file) => {
  return new Promise((res, rej) => {
    const reader = new FileReader()
    reader.onloadend = () => {
      res(reader.result)
    }
    reader.readAsDataURL(file)
  })
}

export const getFormattedSize = (bytes, decimals = 2) => {
  if (!bytes) return "0 Bytes"
  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export const openUrlInNewTab = (url, target = "_blank") => {
  if (url && url.trim().length > 0) {
    let win = window.open(url, target)
    win.focus()
  }
}

export const copyToClipboard = (copyText) => {
    navigator.clipboard.writeText(copyText);
}

export const getClickableLink = (link) => {
  return link.startsWith("http://") || link.startsWith("https://") ? link : `http://${link}`;
};

export const isEmptyObject = (value) => {
  return Object.keys(value).length === 0 && value.constructor === Object
}

export function cn(...inputs) {
  return twMerge(clsx(inputs))
}

export function getContrastYIQ(hexColor) {
  // Remove the hash at the start if it's there
  hexColor = hexColor.replace(/^#/, '');
  
  // Convert 3-digit hex to 6-digit hex
  if (hexColor.length === 3) {
    hexColor = hexColor.split('').map(hex => hex + hex).join('');
  }

  const r = parseInt(hexColor.substr(0, 2), 16);
  const g = parseInt(hexColor.substr(2, 2), 16);
  const b = parseInt(hexColor.substr(4, 2), 16);
  
  // Calculate the YIQ ratio
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  
  // Return black or white depending on the YIQ ratio
  return yiq >= 128 ? 'black' : 'white';
}
export function adjustColor(color, percent) {
  // Remove the hash at the start if it's there
  color = color.replace(/^#/, '');

  // Parse the color into RGB components
  let num = parseInt(color, 16);
  let r = (num >> 16) + Math.round(255 * (percent / 100));
  let g = ((num >> 8) & 0x00FF) + Math.round(255 * (percent / 100));
  let b = (num & 0x0000FF) + Math.round(255 * (percent / 100));
  // Clamp each value between 0 and 255
  r = Math.min(255, Math.max(0, r));
  g = Math.min(255, Math.max(0, g));
  b = Math.min(255, Math.max(0, b));

  // Convert the new RGB values back into a hex color
  return '#' + ((r << 16) | (g << 8) | b).toString(16).padStart(6, '0');
}

export function extractTextFromHTML(html) {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = html;
  return tempDiv.textContent || tempDiv.innerText || "";
}